@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Coolvetica-rg';
    src: font-url('./assets/font/Coolvetica-rg.otf') format('otf');
  }
}


